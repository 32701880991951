<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <b-table striped bordered hover :fields="columns" :items="teams">
          <div slot="position" slot-scope="row" class="text-right">
            {{ row.index + 1 }}
          </div>
          <div slot="name" slot-scope="row">
            <router-link :to="{ name: 'TeamHome', params: { lang:lang, console: console, teamSlug: row.item.slug } }">
              <div>
                <img :src="row.item.image" :alt="row.item.name" class="img-icon mr-2">
                {{ row.item.name }}
              </div>
            </router-link>
          </div>
          <div slot="dts" slot-scope="row">
            <router-link v-if="row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.dt.slug } }">
              <img :src="row.item.dt.avatar" :alt="row.item.dt.nick" class="img-icon mr-2">
              DT {{ row.item.dt.nick }}
            </router-link>
            <router-link v-if="row.item.codt && !row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.codt.slug } }">
              <img :src="row.item.codt.avatar" :alt="row.item.codt.nick" class="img-icon mr-2">
              CODT {{ row.item.codt.nick }}
            </router-link>
          </div>
          <div slot="players" slot-scope="row">{{ row.item.players }}</div>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      teams: [],
      columns: [
        { key: 'position', label: '' },
        { key: 'name', label: this.$t('team'), sortable: true },
        { key: 'dts', label: 'DT / CODT', sortable: true },
        { key: 'players', label: this.$t('players'), sortable: true },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'currentSeason',
      'divisionFront'
    ])
  },
  watch: {
    currentSeason() {
      this.fetchData()
    }
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'home')
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const cons = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const params = {
        season: this.currentSeason
      }
      this.path = `${lang}/console/${cons}/division/${slug}/teams`
      this.$axios.get(this.path, { params }).then((response) => {
        this.teams = response.data.data
      })
    }
  }
}
</script>
